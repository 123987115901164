form fieldset {
  max-width: unset !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.form-columns-2 {
  @apply grid tablet-sm:grid-cols-2 gap-x-4 desktop-lg:gap-x-6;
}

.hs-form-field {
  width: 100% !important;
  @apply mb-4 tablet-sm:mb-3 desktop-sm:mb-3 desktop-lg:mb-5;
}

.hs-form-field label {
  color: #000;
  display: block;
  @apply text-mobile-sm/body-4-bold tablet-sm:text-tablet-sm/body-4-bold desktop-lg:text-desktop-large/body-3-bold;
}

.hs-form-field.hs_privacy_policy_agreement label {
  @apply text-mobile-sm/body-4 tablet-sm:text-tablet-sm/body-4 desktop-lg:text-desktop-large/body-3;
}

.hs-form-field label .hs-form-required {
  @apply text-secondaryPassionPink;
}

.hs-form-field .input {
  width: 100%;
}

.hs-form-field input[type='text'],
.hs-form-field input[type='email'],
.hs-form-field input[type='tel'],
.hs-form-field input[type='number'],
.hs-form-field textarea,
.hs-form-field select {
  width: 100%;
  border-radius: 8px;
  background: rgba(219, 219, 219, 0.4);
  @apply mt-1.5 tablet-sm:mt-2 desktop-lg:mt-2.5 border border-transparent;
  @apply py-1.5 px-2.5 tablet-sm:px-4 tablet-sm:py-3 desktop-lg:py-4;
  @apply text-mobile-sm/body-2 tablet-sm:text-tablet-sm/body-4 desktop-lg:text-desktop-large/body-3;
}

.hs-form-field input[type='text']::placeholder,
.hs-form-field input[type='email']::placeholder,
.hs-form-field input[type='tel']::placeholder,
.hs-form-field input[type='number']::placeholder,
.hs-form-field textarea::placeholder,
.hs-form-field select:invalid {
  @apply text-cometBlack;
}

.hs-form-field input[type='text']:focus,
.hs-form-field input[type='email']:focus,
.hs-form-field input[type='tel']:focus,
.hs-form-field input[type='number']:focus,
.hs-form-field textarea:focus,
.hs-form-field select:focus {
  background-color: #fff;
  outline: none;
  @apply bg-white border-deepSkyBlue;
}

.hs-form-field select {
  padding-right: 15px;
  appearance: none;
  background: rgba(219, 219, 219, 0.4);
  background-image: url('/svgs/dropdown-arrow.svg');
  background-repeat: no-repeat;
  background-size: 25px 7px;
  background-position: right 8px center;
  background-clip: border-box;
}

.hs-form-field input[type='checkbox'] {
  margin: 0;
  margin-right: 12px;
  width: 20px !important;
  height: 20px;
  appearance: none;
  background-color: #f1f1f1;
  border-radius: 4px;
  position: relative;
  top: 5px;
  border: 0;
  cursor: pointer;
}

.hs-form-field input[type='checkbox']:checked {
  background-color: #185ad2;
  background-image: url('/svgs/check.svg');
  background-repeat: no-repeat;
  background-size: 12px 9px;
  background-position: center;
  background-clip: border-box;
}

.hs_privacy_policy_agreement {
  @apply mt-0 tablet-sm:mt-5 desktop-sm:mt-1 mb-3 tablet-sm:mb-4 desktop-lg:mb-6;
}

.hs_privacy_policy_agreement input {
  display: none;
}

.hs-form-field a {
  color: #118acb;
  cursor: pointer;
}

.hs-form-field a:hover {
  text-decoration: underline;
}

.hs-input {
  width: 100% !important;
}

.actions input[type='submit'] {
  @apply spr-btn-primary-default;
}

.hs-input.error.invalid {
  @apply border border-secondaryPassionPink;
}

.field.hs-form-field .hs-error-msg,
.emailable-error-label {
  @apply text-secondaryPassionPink block;
  @apply text-mobile-sm/body-4 tablet-sm:text-tablet-sm/body-5 desktop-lg:text-desktop-large/body-4;
  @apply mt-1.5 tablet-sm:mt-2 desktop-lg:mt-2.5;
}

.hs_error_rollup {
  display: none !important;
}

.hs-form-field .emailable-spinner-container {
  position: relative;
}

.spinner {
  display: block;
  fill: rgba(17, 138, 203);
  height: 20px;
  width: 20px;
  line-height: normal;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: 2px;
}

.emailable-error-input {
  border: 1px solid #ff6060 !important;
}

.hs-form-booleancheckbox-display {
  padding-left: 0px !important;
}

.submitted-message {
  font-size: 15px;
  text-align: center;
}

.submit-button-disabled {
  cursor: not-allowed !important;
  opacity: 0.6;
}
